<template>
  <div>
    <table class="print-insalubrity table-bordered">
      <tr>
        <th class="col-first">
          <img src="../assets/images/logo-nova.png" alt="HOFácil" width="260" />
        </th>
        <th class="emph">
          Relatório de impressão gerado do software de Higiene Ocupacional para
          agentes químicos HO Fácil Web.
          <br />
          As informações aqui mostradas são relativas às pesquisas para
          verificação legal conforme os anexos 11, 12 e 13 da NR 15.
        </th>
      </tr>
      <template v-for="item in summary">
        <template v-for="field in model.tableFieldsPrint">
          <tr
            :key="`key_${item.legalSearchId}_${field.key}`"
            v-if="model.getActivity(item)"
          >
            <template v-if="field.key === 'toleranceList'">
              <td class="emph col-first">{{ field.value }}</td>
              <td>
                <table class="table-tolerance">
                  <thead>
                    <tr>
                      <th
                        class="emph"
                        v-for="item in field.list"
                        :key="item.key"
                      >
                        {{ item.value }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        v-for="fieldTolerance in field.list"
                        :key="fieldTolerance.key"
                      >
                        {{ model.getValue(fieldTolerance.key, item) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </template>
            <template v-else>
              <td class="emph">{{ field.value }}</td>
              <td v-html="model.getValue(field.key, item)"></td>
            </template>
          </tr>

          <tr :key="`key_${item}_${field.key}`" v-else>
            <template v-if="field.key === 'toleranceList'">
              <td class="emph">{{ field.value }}</td>
              <td>Agente necessita de determinação de atividade</td>
            </template>
            <template v-else>
              <td class="emph">{{ field.value }}</td>
              <td>
                <template v-if="field.key === 'ps'">
                  Agente necessita de determinação de atividade
                </template>
                <template v-else>
                  {{ model.getValue(field.key, item) }}
                </template>
              </td>
            </template>
          </tr>
        </template>
        <tr :key="`blank_${item.legalSearchId}`">
          <td colspan="2"></td>
        </tr>
      </template>
    </table>
  </div>
</template>

<script>
import Insalubrity from '@/models/Insalubrity'
export default {
  data() {
    return {
      model: Insalubrity,
      selectedAgent: {},
    }
  },
  computed: {
    summary() {
      return this.$store.getters['legalSearch/summary']
    },
    user() {
      return this.$store.getters['user/current']
    },
  },
  async mounted() {
    await this.$store.dispatch('userData/updatePrintedReports', this.user.id)
    window.print()
  },
}
</script>

<style scoped>
@media print {
  * {
    -webkit-print-color-adjust: exact;
  }
}
.title,
.table-print {
  width: 100vw;
}

.title tr th,
.table-print tr th {
  width: 15vw;
}

.title tr td,
.table-print tr td {
  width: 85vw;
}
.table-tolerance {
  width: 100%;
}
.table-tolerance tr th {
  padding: 5px 10px;
}
.emph {
  background: var(--sapphire-blue);
  color: white;
  font-weight: bold;
}
th,
td {
  padding: 10px;
}
.title tr td {
  background: var(--sapphire-blue);
  color: white;
  font-weight: bold;
  padding-top: 30px;
}

.title tr th:first-child {
  background-color: white;
  font-weight: bold;
  height: 100px;
}

.title tr th:first-child img {
  height: 90px;
}

.table-print tr th {
  background-color: var(--sapphire-blue);
  color: var(--white);
}
/* .print-insalubrity tr th:first, .print-insalubrity tr td:first{ */
.col-first {
  /* background-color: red; */
  width: 15%;
}
</style>
